import React from "react";
import "./header.css";
import star from "../../../src/assets/images/home-page/Star.png";
import fish from "../../../src/assets/images/home-page/Fish.png";
import ballons from "../../../src/assets/images/home-page/Ballons.png";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazy-load";


const Header = () => {
  const [t, i18n] = useTranslation();
  
  return (
    <header>
      <div className="header container">
        <div className="header-images">
          <img src={star} alt="" className="header-star" />
          <img src={fish} alt="" className="header-fish" />
        </div>
        <div className='words'>
          <h1 className={ i18n.dir()=== "ltr"?"edittext":" "}>
            {t("header.title")}
            <br />
            <span>{t("header.sacond-title")}</span>
          </h1>
          <div className="header-ballons">
            <LazyLoad>
              <img src={ballons} alt="" />
            </LazyLoad>
        </div>
        </div>

      </div>
    </header>
  );
};
export default Header;