import React, {Fragment} from "react";
import "./aboutSection2.css"
import funnygirl1 from "../../assets/images/about/funnygirl1.png";
import funnygirl2 from "../../assets/images/about/funnygirl2.png";
import funnyboy from "../../assets/images/about/funnyboy.png";
import clouds from "../../assets/images/about/clouds.png";
import heart from "../../assets/images/about/heart.png";
import bone1 from "../../assets/images/about/bone1.png";
import bone2 from "../../assets/images/about/bone2.png";
import dice from "../../assets/images/about/Dice.png";
import skull from "../../assets/images/about/Skull.png";
import shapes from "../../assets/images/about/shapes.png";
import { useTranslation } from "react-i18next";


const AboutSection2 = () => {
    const [t, i18n] = useTranslation();
    return (
        <Fragment >
            <div className='about-section2'>
                <div className='about-section2-top'>
                    <div className='about-section2-images container'>
                        <img src={funnygirl1} alt="" className='about-section2-funnygirl1'/>
                        <img src={funnyboy} alt="" className='about-section2-funnyboy'/>
                        <img src={funnygirl2} alt="" className='about-section2-funnygirl2'/>
                    </div>
                    <div className='about-section2-img'>
                        <img src={clouds} alt="" className='about-section2-clouds'/>
                    </div>
                </div>
                <div className='about-section2-bottom container'>
                    <div className='about-section2-right '>
                        <img src={heart} alt="" className='about-section2-heart'/>
                        <img src={bone1} alt="" className='about-section2-bone'/>
                        <img src={dice} alt="" className='about-section2-dice1'/>
                        <img src={dice} alt="" className='about-section2-dice2'/>
                        <img src={skull} alt="" className='about-section2-skull'/>
                    </div>
                    <div className='about-section2-middle'>
                        <img src={shapes} alt="" className='shapes'/>
                        <div className="sectionThree-contenier">
                        <h1>{t('about.sectionThree.heading')}</h1>
                    
                            <p>{t('about.sectionThree.txt')}</p>
                            <p>{t('about.sectionThree.txtSacond')}</p>
                      
                        </div>
                        <img src={dice} alt="" className='section2-dice section2-phone'/>
                        <img src={bone2} alt="" className='section2-bone section2-phone'/>
                        <img src={heart} alt="" className='section2-heart section2-phone'/>
                    </div>
                    <div className='about-section2-left'>
                        <img src={skull} alt="" className='about-section2-skull1'/>
                        <img src={dice} alt="" className='about-section2-dice3'/>
                        <img src={bone2} alt="" className='about-section2-bone1'/>
                        <img src={skull} alt="" className='about-section2-skull2'/>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default AboutSection2