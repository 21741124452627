import React from "react";
import logo from "./ifooter/Group.png";
import "./footer.css";
import image1 from "./ifooter/Group 1000001041.png";
import image2 from "./ifooter/Frame.png";
import image3 from "./ifooter/Group 1000001159.png";
import image4 from "./ifooter/Mask group.png";
import image5 from "./ifooter/Path Way.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const Footer = () => {
  const [t, i18n] = useTranslation();

  return (
    <>
      <footer>
        <div className="footer-top">
          <div className="footer-txt">
            <div className="footer-logo">
              <img src={logo} alt="foot-logo" />
              <p>
                {t('footer.txt')}
              </p>
            </div>
          </div>
          <div className="footer-links">
            <ul>
             <li><Link to="about" >{t('navbar.link1')}</Link></li>
             <li><Link to="404">{t('navbar.link2')}</Link></li>
              <li> {t('navbar.link4')} </li>
              <li>{t('navbar.link3')}</li>
              <li> {t('navbar.link5')}</li>
              <li>{t('navbar.link7')} </li>
            </ul>
          </div>
        </div>

        <div className="footer-copyright">
          <p className={ i18n.dir()=== "ltr"?"fs-5":" "}>
              {t('footer.copyright')}<span>{t('footer.anhjj')}</span>@2023
          </p>
        </div>
        <div className="foot-im1">
          <img src={image1} alt="back-foot" />
        </div>

        <div className="foot-im2">
          <img src={image2} alt="back-foot" />
        </div>
        <div className="foot-im3">
          <img src={image3} alt="back-foot" />
        </div>
        <div className="foot-im4">
          <img src={image4} alt="back-foot" />
        </div>
        <div className="foot-im5">
          <img src={image5} alt="back-foot" />
        </div>
      </footer>
    </>
  );
};
export default Footer;
