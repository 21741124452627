import React from 'react';
import gift from "./static-image/Gift - Pink (Border).png";
import crown from "./static-image/Crown (Border).png";
import cup from "./static-image/Cup (Border).png"
import Ghost from "./static-image/Ghost Head.png";
import  "./static.css";
import { useTranslation } from "react-i18next";

export default function Statices() {

    const [t, i18n] = useTranslation();
    return (
        <div className="Statices">
            <div className="container">
                <div className="row">
                    <div className='statics-info'>
                
                    <div style={{backgroundColor:"#FFDEEB"}} className='statics-box'>
                        <img src={gift} alt="gif-static" />
                        <h4>{t('statices.courcesNumber')}</h4>
                        <p>+30</p>
                    </div>
                    <div style={{backgroundColor:"#FFE8CC"}} className='statics-box'>
                        <img src={cup} alt="gif-static" />
                        <h4>{t('statices.studentNumber')}</h4>
                        <p>+30</p>
                    </div>
                    <div style={{backgroundColor:"#E5DBFF"}} className='statics-box'>
                        <img src={Ghost} alt="gif-static" />
                        <h4>{t('statices.gamesNumber')}</h4>
                        <p>+30</p>
                    </div>
                    <div style={{backgroundColor:"#FFF3BF"}} className='statics-box'>
                        <img src={crown} alt="gif-static" />
                        <h4>{t('statices.experienceYears')}</h4>
                        <p>+30</p>
                    </div>
                </div>
                </div>

            </div>


        </div>
    )
}