import React, {Fragment} from 'react';
import Header from '../../components/header/Header';
import Statics from '../../components/statices-info/Statics';
import Stages from '../../components/learning-stages/Stages';
import Content from '../../components/content/Content';
import Down from '../../components/download-app/Down';


const Home = () => {
  return (
    <Fragment>
      <div className="navbar-header">
          <Header />
      </div>
      <Statics />
      <Stages />
      <Content />
      <Down />
    </Fragment>
  )
}

export default Home;