import React from 'react';

const Aboute = ({image,head,text}) => {
  return (
    <div className='subabout'>
        <div className='row subabout-content'>
            <img src={image} alt='rocket' />
            <h2>{head}</h2>
            <p>{text}</p>
        </div>
    </div>
  )
}

export default Aboute;