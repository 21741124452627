import React from 'react';
import rocket from "./learning stages-images/Frame.png";
import girl from "./learning stages-images/Group 1000001035.png";
import boy from "./learning stages-images/Group 1000001038.png"
import "./stages.css";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazy-load";

export default function Stages() {
    const [t, i18n] = useTranslation();
    return (
        <div className="container learning-stages">
            <div className="row">
                <div className="stages-info">
                    <div className="stage-img">
                        <LazyLoad>
                            <img src={rocket} alt="boy-rocket" />
                        </LazyLoad>
                    </div>
                    <div className="stage">
                        <div>
                            <button>{t('Learning-stages.section-one.btn')}</button>
                            <h3>{t('Learning-stages.section-one.heading')}</h3>
                            <p>{t('Learning-stages.section-one.txt')}</p>
                       </div>
                    </div>
                    <div className="stage">
                        <div>
                        <button>{t('Learning-stages.section-one.btn')}</button>
                        <h3>{t('Learning-stages.section-one.heading')}</h3>
                        <p>{t('Learning-stages.section-one.txt')}</p>
                        </div>
                    </div>
                    <div className="stage-img">
                        <LazyLoad>
                            <img src={boy} alt="boy-rocket" />
                        </LazyLoad>
                    </div>
                    <div className="stage-img">
                        <LazyLoad>
                            <img src={girl} alt="boy-rocket" />
                        </LazyLoad>
                    </div>
                    <div className="stage">
                         <div>
                            <button>{t('Learning-stages.section-one.btn')}</button>
                            <h3>{t('Learning-stages.section-one.heading')}</h3>
                            <p>{t('Learning-stages.section-one.txt')}</p>
                         </div>
                    </div>


                </div>
            </div>

        </div>
    )}

