import React, {Fragment, useState} from "react";
import phone1 from "./idownload/Mask group (1).png";
import phone2 from "./idownload/Mask group (2).png";
import andorid from "./idownload/Vector (3).png";
import iphone from "./idownload/Vector (4).png";
import cup from "./idownload/cup.png";
import watts from "./idownload/wattsapp.png";
import layer from "./idownload/Layer 20.png";
import avatar1 from "./idownload/Avatar1.png";
import avatar2 from "./idownload/Avatar2.png";
import avatar3 from "./idownload/Avatar3.png";
import badge1 from "./idownload/badge1.png";
import badge2 from "./idownload/badge2.png";
import badge3 from "./idownload/badge3.png";
import closeButton from "./idownload/closeButton.png";
import clouds from "./idownload/threeClouds.png";
import { useTranslation } from "react-i18next";
import "./down.css";

const Down = () => {
  const [t, i18n] = useTranslation();
  const [board,setBoard]=useState(false);

  return (
      <Fragment>
        <div className="down container">
          <div className="row">
            <div className="down-content">
              <h2>{t('download-section.heading')}</h2>
              <div className="down-images">
                <img src={phone1} alt="android" className="andriod1" />
                <img src={phone2} alt="android" className="andriod2" />
              </div>
              <div className="down-btns">
                <button>
                  <div className="end-btn">
                    <div>
                      <img src={andorid} alt="andorid-icon" className="andorid-icon" />
                    </div>
                    <div>{t('download-section.andriod')}</div>
                    <img src={layer} alt="layer" className="layer" />
                  </div>
                </button>
                <button>
                  <div className="end-btn">
                    <div>
                      <img src={iphone} alt="andorid-icon" className="andorid-icon" />
                    </div>
                    <div>{t('download-section.iphone')}</div>
                    <img src={layer} alt="layer" className="layer" />
                  </div>
                </button>
              </div>
            </div>
            <div className="arang">
              <div>
                <img src={cup} alt="cup" className='icons' onClick={()=>setBoard(!board)}/>
                <img src={watts} alt="wattsapp" className='icons'/>
                <div className='leaderboard'>
                  <img src={clouds} alt='cloud' className='threeClouds'/>
                  <ul className= {board?'show avatars':'hide'} >
                    <img src={closeButton} alt='' className='close'/>
                    <li>
                      <div className='avatars-info'>
                        <img src={avatar1} alt="avatar1" />
                        <img src={badge1} alt="badge1" />
                      </div>
                      <div className='score-points'>
                        <span className='top-score'>سلمي محمود</span>
                        <span>1</span>
                      </div>
                    </li>
                    <li>
                      <div className='avatars-info'>
                        <img src={avatar2} alt="avatar2" />
                        <img src={badge2} alt="badge2" />
                      </div>
                      <div className='score-points'>
                        <span className='top-score'>سلمي محمود</span>
                        <span>1</span>
                      </div>
                    </li>
                    <li>
                      <div className='avatars-info'>
                        <img src={avatar3} alt="avatar3" />
                        <img src={badge3} alt="badge3" />
                      </div>
                      <div className='score-points'>
                        <span className='top-score'>سلمي محمود</span>
                        <span>1</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </Fragment>
  );
};

export default Down;