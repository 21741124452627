import React, {Fragment} from "react";
import "./aboutHeader.css"
import smileChild from "../../assets/images/about/smilechild.png"
import wave from "../../assets/images/about/wave.png"
import { useTranslation } from "react-i18next";
const AboutHeader = () => {
    const [t, i18n] = useTranslation();

    return (
        <Fragment >
            <div className="about-page">
                <div className=" container">

                    <div className='about-header'>
                        <div  className={ i18n.dir()=== "ltr"?"smile-child smile-child-phone":"smile-child "}>
                            <img src={smileChild} alt=""/>
                        </div>
                        <div className="about-content">
                            <div>
                            <h1>{t("about.title")}</h1>
                            <p>{t('about.header')}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={wave} alt="" className='about-wave'/>
            </div>
        </Fragment>
    )
}
export default AboutHeader