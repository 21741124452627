
import React, {Fragment} from "react";
import AboutSecion1 from "../../components/about-cources/AboutSecion1";
import AboutHeader from "../../components/about-header/AboutHeader"
import AboutSection2 from "../../components/about-section2/AboutSection2"

const About = () => {
    return (
        <Fragment >
            <AboutHeader/>
            <div className="about-section1-content">
                <AboutSecion1 />
            </div>
            <AboutSection2/>
        </Fragment>
    )
}
export default About;
