import React from "react";
import "./aboutSection1.css";
import Aboute from "./subabout";
import cup from "../../assets/images/about/Frame.png";
import coins from "../../assets/images/about/Coins.png";
import rocket from "../../assets/images/about/cup.png";
import dice from "../../assets/images/about/Dice.png";
import skull from "../../assets/images/about/Skull .png";
import image2 from "../../assets/images/about/about-image1.png";
import boons from "../../assets/images/about/boon.png";
import heart from "../../assets/images/about/heart.png";
import { useTranslation } from "react-i18next";

const AboutSecion1 = () => {
  const [t, i18n] = useTranslation();
  return (
    <>
      <div className="about-one container">
        <div>
          <div className="about-one-heading">
            <div>
              <h2 className="mb-4">{t('about.sectionOne.heading')}</h2>
              <p>
                 {t('about.sectionOne.txt')}  
              </p>
              <img src={skull} alt="skull" />
            </div>
          </div>
          <div className="about-one-content">
            <Aboute
              head={t('about.sectionOne.contentOne.title')}
              image={rocket}
              text={t('about.sectionOne.contentOne.txt')}
            />
            <Aboute
              head={t('about.sectionOne.contentTwo.title')}
              image={cup}
              text={t('about.sectionOne.contentTwo.txt')}
            />
            <Aboute
              head={t('about.sectionOne.contentThree.title')}
              image={coins}
              text={t('about.sectionOne.contentThree.txt')}
            />
          </div>
        </div>
        <img src={dice} alt="dice" className="diceone" />
        <img src={dice} alt="dice" className="dicetwo" />
        <img src={dice} alt="dice" className="dicethree" />
      </div>
      <div className="about-one-section2">
        <div className="about-one-image">
          <img src={image2} alt="back" className={ i18n.dir()=== "ltr"?"editdir image-phone":"image-phone"} />
        </div>
        <div className="about-one-section2-text">
          <h2 className="mb-4">{t('about.sectionTwo.heading')}</h2>
          <p>
            {t('about.sectionTwo.txt')}
          </p>
          <p>
            {t('about.sectionTwo.txtSacond')}
          </p>
        </div>

        <img src={dice} alt="dice" className="sec2-image" />
        <img src={dice} alt="dice" className={ i18n.dir()=== "ltr"?"sec2-imageEdit sec2-image2-edit":"sec2-image2"} />
        <img src={dice} alt="dice" className="sec2-image3" />
        <img src={skull} alt="skull" className="sec2-skull" />
        <img src={boons} alt="boon" className="sec2-boon" />
        <img src={heart} alt="heart" className="sec2-heart" />
      </div>
    </>
  );
};

export default AboutSecion1;
