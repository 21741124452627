import React from "react";
import logo from "../../assets/images/home-page/Logo.png";
import mountains from "../../assets/images/home-page/Mountains.png";
import pen from "../../../src/assets/images/home-page/Pen.png";
import Notebook from "../../../src/assets/images/home-page/Notebook.png";
import Credits from "../../../src/assets/images/home-page/Credits.png";
import Cup from "../../../src/assets/images/home-page/Cup-Border.png";
import Bear from "../../../src/assets/images/home-page/Bear-Border.png";
import Mail from "../../../src/assets/images/home-page/Mail.png";
import Frame from "../../../src/assets/images/home-page/Frame.png";
import menu from "../../../src/assets/images/home-page/menu.png";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";
import "./navbar.css"

const Navbar = () => {
  const [menuPic,setMenuPic]=useState(false);

  const [t, i18n] = useTranslation();
   return (
      <div className="container">
        <div className="navbar">
          <div className="logo">
            <img src={logo} alt="" className="img-logo" />
          </div>
          <img src={menu} alt='' className='menu-pic' onClick={()=>setMenuPic(!menuPic)}/>
          <div className="about">
            <ul className={menuPic?'show':'hide'}>
              <li>
                <img src={mountains} alt="" />
                <Link to="/">
                  <p className="title">{t("navbar.link1")}</p>
                </Link>
              </li>
              <li>
                <img src={pen} alt="" />
                <Link to="/about" >
                  <p>{t("navbar.link2")}</p>
                </Link>
              </li>
              <li>
                <img src={Notebook} alt="" />
                <Link to="/courses" >
                  <p>{t("navbar.link3")}</p>
                </Link>
              </li>
              <li>
                <img src={Credits} alt="" />
                <Link to="/packages" >
                  <p>{t("navbar.link4")}</p>
                </Link>
              </li>
              <li>
                <img src={Cup} alt="" />
                <Link to="/leadboard" >
                  <p>{t("navbar.link5")}</p>
                </Link>
              </li>
              <li>
                <img src={Bear} alt="" />
                <Link to="/store" >
                  <p>{t("navbar.link6")}</p>
                  </Link>
              </li>
              <li className={ i18n.dir()=== "ltr"?"me-4":" "}>
                <img src={Mail} alt="" />
                <Link to="/contact" >
                  <p >{t("navbar.link7")}</p>
                </Link>
              </li>
              <div className='clouda'></div>
            </ul>
          </div>
          <img src={Frame} alt="" className='profile'/>
          <select onChange={(e) => changeLanguage(e.target.value)} class="option-lang">
            <option className="option-lang" value="en" ><span>English</span></option>
            <option className="option-lang" value="ar">العربية</option>
          </select> 
        </div>
        </div>
   )
}

export default Navbar;
